import { Component, OnInit, Inject, ViewChild, ViewChildren,QueryList } from '@angular/core';
import { Router } from "@angular/router";
import { MatPaginator, MatTableDataSource, MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatSortModule, MatSort, MatSnackBar } from '@angular/material';
import { FormGroup, FormControl, Validators, ReactiveFormsModule } from '@angular/forms';
import {PageEvent} from '@angular/material';
import { Papa } from 'ngx-papaparse';
import {MatChipInputEvent} from '@angular/material';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { deleteElement } from '../delete/eliminar-element.component';
import { EmojisComponent } from '../emojis/emojis.component';
import { ServicesService } from '../services.service';
import { Observable } from 'rxjs';
import { LocalSaveService } from '../local-save.service';
import { ComponentPageTitle } from '../page-title/page-title';
import { FileExplorerComponent } from '../file-explorer/file-explorer.component';
import { MatChipsModule, MatChip, MatChipList } from '@angular/material/chips';
import { EmojiSearch } from '@ctrl/ngx-emoji-mart';
import * as emoji from 'node-emoji';
import { ValidatemenusService } from '../validatemenus.service';

@Component({
	selector: 'app-nodos',
	templateUrl: './nodos.component.html',
	styleUrls: ['./nodos.component.css']
})
export class NodosComponent implements OnInit {

	@ViewChild(MatPaginator) paginator : MatPaginator;
	estados:any=[];
	displayedColumns = ['nombre', 'descripcion', 'tipo', 'fecha_creacion', 'accion'];
	nodes = new MatTableDataSource([]);
	filter_search = "";
	filter_type = "";
	isLoadingResults = false;
	nodeCount = 0;

	constructor(
		public componentPageTitle: ComponentPageTitle, 
		private router: Router, 
		private localSave: LocalSaveService, 
		public dialog: MatDialog, 
		private validatemenu: ValidatemenusService,
		public services: ServicesService
		) { }

	ngOnInit() {
		this.componentPageTitle.title = 'Plantillas de nodos';
		this.reload();
	}

	fnvalidatemenu(menu) {
		return this.validatemenu.permiso(menu);
	}

	reload(){
		var filters = [this.filter_search,this.paginator.pageIndex,this.filter_type];
		this.isLoadingResults = true;
		this.services.getNodes({filters: filters, todos: 0}).subscribe(data => {
			this.nodes = new MatTableDataSource(data);
			this.services.getNodesCount({filters: filters, todos: 0}).subscribe(data => {
				this.nodeCount = data[0].total;
				this.isLoadingResults = false;
			});
		});
	}

	search() {
		this.filter_search = this.filter_search.trim();
		this.filter_type = this.filter_type.trim();
		if(this.paginator.pageIndex > 0){
			this.paginator.firstPage();
		}
		this.reload();
	}

	ingresoNodo() {
		let dialogRef = this.dialog.open(ingresoNodo, {
			disableClose: true,
			width: '1000px',
			height: '90%',
			data: {}
		});

		dialogRef.afterClosed().subscribe(result => {
			this.search();
		});
	}

	modificar(node){
		let dialogRef = this.dialog.open(ingresoNodo, {
			disableClose: true,
			width: '1000px',
			height: '90%',
			data: {node}
		});

		dialogRef.afterClosed().subscribe(result => {
			this.search();
		});
	}

	eliminar(node){
		let dialogRef = this.dialog.open(deleteElement, {
			disableClose: true,
			data: {node}
		});
		dialogRef.afterClosed().subscribe(result => {
			if(result){
				this.services.deleteNode({codigo: node.codigo_nodo}).subscribe(data => {
					
						console.log(data);
						this.search();
					
				});
			}
		});
	}
}

@Component({
	selector: 'ingreso-nodo',
	templateUrl: 'ingreso-nodo.html',
	styles: ['::ng-deep.mat-tab-body-wrapper {height:100% !important} .example-table-container {position: relative;max-height: 400px;overflow: auto;}.example-loading-shade {position: absolute;top: 0;left: 0;bottom: 56px;right: 0;background: rgba(0, 0, 0, 0.15);z-index: 1;display: flex;align-items: center;justify-content: center;}.example-container {position: relative;}.mat-header-cell{text-align: center;}.mat-cell{text-align: center;}.mat-raised-button[disabled] {box-shadow: none;color: white !important;border: transparent !important;background-color: #00A9E0;background-color: rgba(0,0,0,.12) !important;} .mat-form-field {	margin:5%;} .addButton{	float: bottom;	margin-top: -1%;	margin-bottom: 1%;	background-color:#00A9E0;} ::ng-deep .mat-sort-header-container { display:flex; justify-content:center;} th.mat-sort-header-sorted { color: black; text-align: center;} .mat-paginator-container{justify-content: center !important;} table { width: 100%;}']
})

export class ingresoNodo {
	@ViewChild(MatPaginator) paginator : MatPaginator;
	info;
	nodoActivo = -1;
	nombreActivo = "";
	nodoForm = new FormGroup({
		codigo_nodo: new FormControl(''),
		etiqueta: new FormControl({value:'',disabled:true},Validators.required),
		columnas: new FormControl({value:'',disabled:true},Validators.required),
		nombre: new FormControl('', [Validators.required, Validators.maxLength(45)]),
		descripcion: new FormControl('', [Validators.required, Validators.maxLength(300)]),
		codigo_tipo_nodo: new FormControl('', Validators.required),
		parametro: new FormControl('', Validators.required),
		codigo_parametro: new FormControl(''),
		direccion_parametro: new FormControl(''),
		tipo_nodo: new FormControl(''),
		tipo_consumo: new FormControl('0'),
		frases: new FormControl(''),
		bot: new FormControl(),
		area: new FormControl(),
		pais: new FormControl(),
		segmento: new FormControl(),
		im: new FormControl('1'),
		// tipo_seleccion: new FormControl({value: '', disabled: true}, Validators.required),
		// variable: new FormControl({value: '', disabled: true},  [Validators.required, Validators.maxLength(45)]),
		seleccion: new FormControl(),
		testchip: new FormControl(),
		nfrases: new FormControl()
	});

	nodoSearchForm = new FormGroup({
		area: new FormControl(''),
		pais: new FormControl(''),
		segmento: new FormControl('')
	});

	perfilDetalleForm = new FormGroup({
		frase: new FormControl('', Validators.required)
	});

	wasModified;
	perfilesData;
	perfiles = new MatTableDataSource([]);
	node:any=[];
	selected = 0;
	filter_search = "";
	isLoadingResults = false;
	nodeDetailCount = 0;
	tiposNodo:any = [];
	displayedColumns = ["nombre", "descripcion", "asociado"];
	isEditing = false;
	board;
	isMultimedia:boolean = false;
	isSelection:boolean = false;
	isMenu:boolean = false;
	options: any=[];
	filteredOptions: Observable<string[]>;

	visible = true;
	selectable = true;
	removable = true;
	addOnBlur = true;
	nativeemojis:any[] = []; 

	readonly separatorKeysCodes: number[] = [ENTER];
	
	frases: any[] = [];
	parametros: any[] = [];
	filterList = [];
	filterListTable = new MatTableDataSource(this.filterList);
	displayedColumnsFilter = ['nombre', 'descripcion', 'tipo', 'fecha', 'clone'];

	constructor(private emojiSearch: EmojiSearch,private dialogRef: MatDialogRef<ingresoNodo>, @Inject(MAT_DIALOG_DATA) private data: any, private localSave: LocalSaveService, private snackBar: MatSnackBar, private services: ServicesService, private dialog: MatDialog) {
		console.log(this.data.node);
		this.node = this.data.node;
		this.board = this.data.board;
		this.nodoForm.controls.bot.setValue(this.data.bot);
		this.isLoadingResults = true;
		if(typeof(this.node) != 'undefined'){
			this.nodoActivo = this.node.codigo_nodo;
			this.nombreActivo = this.node.nombre;
			var tmpFrases = this.node.frases.substring(12,this.node.frases.length-1).split(",");
			if(tmpFrases.length > 0 && tmpFrases[0].length > 0)
				this.frases = tmpFrases;
			this.services.getParameters(this.node).subscribe(data=> {
				this.nodoForm.controls.parametro.setValue(data[0].parametro);
				this.nodoForm.controls.codigo_parametro.setValue(data[0].codigo_parametro);
				data.forEach(element => {
					this.parametros.push(element.parametro);
				});
				this.loadnativeemojis();
				this.nodoForm.patchValue(this.node);
				this.isEditing = true;
				if(this.node.tipo_nodo === 'multimedia'){
					this.isMultimedia = true;
				}
				if(this.node.tipo_nodo === "menu"){
					this.isMenu = true;
					this.nodoForm.controls.etiqueta.enable();
					this.nodoForm.controls.columnas.enable();
				}
				this.services.getNodeTypes().subscribe(data => {
					this.tiposNodo = data;
					this.isLoadingResults = false;		
				});
			});
		}
		else
		{
			this.services.getNodeTypes().subscribe(data => {
				this.tiposNodo = data;
			});
		}
	}

	loadnativeemojis():void {
		this.nativeemojis = [];
		this.parametros.map((chip)=>{
			console.log(chip);
			this.nativeemojis.push(emoji.emojify(chip));
			console.log(chip);
		});
		console.log(this.nativeemojis);
		
	}

	add(event: MatChipInputEvent): void {
		const input = event.input;
		const value = event.value;
		if ((value || '').trim()) {
			this.frases.push(value.trim());
		}
		if (input) {
			input.value = '';
		}
	}
	
	remove(frase: any): void {
		const index = this.frases.indexOf(frase);
		if (index >= 0) {
			this.frases.splice(index, 1);
		}
	}
	
	add2(event: MatChipInputEvent): void {
		const input = event.input;
		const value = event.value;
		if ((value || '').trim()) {
			this.parametros.push(value.trim());
			this.loadnativeemojis();
		}
		if (input) {
			input.value = '';
		}
		if(this.parametros.length === 1){
			this.nodoForm.controls.parametro.setValue(value.trim());
		}
	}

	remove2(frase: any): void {
		const index = this.nativeemojis.indexOf(frase);
		if (index >= 0) {
			this.parametros.splice(index, 1);
			this.nativeemojis.splice(index, 1);
		}
		if(this.parametros.length == 0){
			this.nodoForm.controls.parametro.setValue('');
		}
	}

	updateType(evt){
		let tipo = this.tiposNodo.find(x => x.codigo_tipo_nodo == evt.value).nombre;
		console.log(tipo); 
		if(tipo.toLowerCase() === "multimedia"){
			this.isMultimedia = true;
		}else if(this.isMultimedia && tipo.toLowerCase() !== "multimedia"){
			this.isMultimedia = false;
		}
		
		// if(tipo.toLowerCase() === "seleccion"){
		// 	this.nodoForm.controls.variable.enable();
		// 	this.nodoForm.controls.tipo_seleccion.enable();
		// 	this.isSelection = true;
		// }else if(this.isSelection && tipo.toLowerCase() !== "seleccion"){
		// 	this.nodoForm.controls.variable.disable();
		// 	this.nodoForm.controls.tipo_seleccion.disable();
		// 	this.isSelection = false;
		// }
		
		if(tipo.toLowerCase() === "menu" || tipo.toLowerCase() === "quickresponse"){
			this.isMenu = true;
			this.nodoForm.controls.etiqueta.enable();
			this.nodoForm.controls.columnas.enable();
		}else if(tipo.toLowerCase() !== "menu"){
			this.nodoForm.controls.etiqueta.disable();
			this.nodoForm.controls.columnas.disable();
			this.isMenu = false;
		}
	}

	openExplorer(){
		let dialogRef = this.dialog.open(FileExplorerComponent, {
			panelClass: 'my-panel',
			width: '100%',
			height: '90%',
			maxWidth: '100vw',
			disableClose: true,
			data: {isCommand: true}
		});
		dialogRef.afterClosed().subscribe( result => {
			if(result){
				const selectedFile = dialogRef.componentInstance.selectedFile;
				this.nodoForm.controls.codigo_parametro.setValue(selectedFile.codigo_archivo);
				this.nodoForm.controls.parametro.setValue(selectedFile.nombre);
				this.nodoForm.controls.direccion_parametro.setValue(selectedFile.direccion);
				this.parametros.push(this.nodoForm.value.parametro);
				this.loadnativeemojis();
			}
		});
	}
	updateProfile(index)
	{
		this.paginator = index;
		//console.log("ACTUALIZA PERFIL ");
		this.reload();
	}

	reload(){
		this.isLoadingResults = true;
		var params = {codigo_nodo:this.nodoActivo, pageIndex: this.paginator.pageIndex*3, filter_search: this.filter_search};
		console.log(params);
		this.services.getProfilesbyNode(params).subscribe(data => {
			console.log(data);
			this.perfilesData = data;
			this.perfiles = new MatTableDataSource(this.perfilesData);
			this.services.getProfilesbyNodeCount(params).subscribe(data => {
				this.nodeDetailCount = data[0].total;
			});
			this.isLoadingResults = false;
		});
	}

	tabsel(evt): void {
		this.selected = evt;
		if(this.selected === 1){
			this.reload();
		}
	}

	asociarPerfil(perfil){
		var params = {id_nodo: this.nodoActivo, id_perfil: perfil.codigo_perfil}
		this.services.insertNodeProfile(params).subscribe(data => {
			var resultado = data[0].resultado;
			if (resultado != -1) {
				this.search();
			}
		});	
	}

	guardarFrases(){
		var params = {frases: this.frases, nodo: this.nodoActivo};
		this.services.updateNodoFrases(params).subscribe(data => {
			var resultado = data[0].resultado;
			if (resultado != -1) {
				this.snackBar.open("El Nodo "+this.nombreActivo+" se guardó correctamente", '', { duration: 3000 });
				this.onNoClick(true);
			}
		});
		this.dialogRef.close(true);
	}

	search(){
		this.filter_search = this.filter_search.trim();
		if(this.paginator.pageIndex > 0){
			this.paginator.firstPage();
		}
		this.reload();
	}

	clonar(nodo)
	{
		this.isLoadingResults = true;
		//console.log(nodo);
		if(typeof(nodo) != 'undefined'){
			var tmpFrases = nodo.frases.substring(12,nodo.frases.length-1).split(",");
			if(tmpFrases.length > 0 && tmpFrases[0].length > 0)
				this.frases = tmpFrases;
			this.services.getParameters(nodo).subscribe(data=> {
				this.nodoForm.controls.parametro.setValue(data[0].parametro);
				this.nodoForm.controls.codigo_parametro.setValue(data[0].codigo_parametro);
				data.forEach(element => {
					this.parametros.push(element.parametro);
				});
				this.loadnativeemojis();
				this.nodoForm.patchValue(nodo);
				if(nodo.tipo_nodo === 'multimedia'){
					this.isMultimedia = true;
				}
				if(nodo.tipo_nodo === "menu"){
					this.isMenu = true;
					this.nodoForm.controls.etiqueta.enable();
					this.nodoForm.controls.columnas.enable();
				}
				this.services.getNodeTypes().subscribe(data => {
					this.tiposNodo = data;
					this.isLoadingResults = false;
					this.tabsel(1);		
				});
			});
		}

	}

	filtrar()
	{

		this.isLoadingResults = true;
		var filtros = {};
		var paisList = "";
		var areaList = "";
		var segmentoList = "";

		var filtros = {};
		if(this.nodoSearchForm.value.area.length > 0)
		{
			this.nodoSearchForm.value.area.forEach( (area,index) => {
				if(index != (this.nodoSearchForm.value.area.length-1))
					areaList = areaList + area.nombre + "|";
				else
					areaList = areaList + area.nombre;
			});
			filtros["filtroArea"] = areaList;
		}
		if(this.nodoSearchForm.value.pais.length > 0)
		{
			this.nodoSearchForm.value.pais.forEach( (pais,index) => {
				if(index != (this.nodoSearchForm.value.pais.length-1))
					paisList = paisList + pais.nombre + "|";
				else
					paisList = paisList + pais.nombre;
			});
			filtros["filtroPais"] = paisList;
		}	
		if(this.nodoSearchForm.value.segmento.length > 0)
		{
			this.nodoSearchForm.value.segmento.forEach( (segmento,index) => {
				var find = '-';
				var re = new RegExp(find, 'g');
				var nombre = segmento.nombre.trim();
				nombre = nombre.replace(re, ' ');

				if(index != (this.nodoSearchForm.value.segmento.length-1))
					segmentoList = segmentoList + nombre + "|";
				else
					segmentoList = segmentoList + nombre;
			});
			filtros["filtroSegmento"] = segmentoList;
		}
		
	}

	modificarNodo(){
		console.log(this.nodoForm.value)
		if(this.nodoForm.value.tipo_nodo != 'menu' && this.nodoForm.value.tipo_nodo != 'quickresponse' && this.nodoForm.value.tipo_nodo != 'seleccion' && this.parametros.length > 1){
			this.snackBar.open("El Tipo de Nodo seleccionado solo acepta 1 parametro!", '', { duration: 3000 });
		}
		else{
			this.nodoForm.controls.seleccion.setValue(this.nodoForm.value.tipo_seleccion + "@" + this.nodoForm.value.variable);
			this.services.updateNode(this.nodoForm.value).subscribe(data => {
				this.wasModified = data[0].resultado;
				if (this.wasModified != -1) {
					this.nombreActivo = this.nodoForm.value.nombre;
					var params = {frases: this.parametros, nodo: this.nodoActivo, tipo: this.nodoForm.value.tipo_nodo, codigo_nodo: this.nodoForm.value.codigo_nodo, parametro: this.nodoForm.value.parametro, codigo_parametro: this.nodoForm.value.codigo_parametro, direccion_parametro: this.nodoForm.value.direccion_parametro};
					this.services.updateNodeOutput(params).subscribe(data => {
						if(typeof(this.board) != 'undefined'){
							console.log(this.nodoForm.value.tipo_nodo);
							let tipo = this.nodoForm.value.tipo_nodo;
							console.log(this.nodoForm.value);
							if(tipo === 'menu'){
								this.services.getOutputsByNode({codigo:this.nodoActivo}).subscribe(outputs => {
									this.board.addMenu(this.nodoForm.value.nombre, this.nodoActivo, this.nodoForm.value.bot, 0, tipo, outputs);
								});
							}if(tipo === 'seleecion'){
								this.services.getOutputsByNode({codigo:this.nodoActivo}).subscribe(outputs => {
									this.board.addSeleccion(this.nodoForm.value.nombre, this.nodoActivo, this.nodoForm.value.bot, 0, tipo, outputs);
								});
							}else if(tipo === 'texto' || tipo === 'multimedia' || tipo === 'pedir_numero'){
								console.log("agrega nodo al board");
								this.board.addTexto(this.nodoForm.value.nombre, this.nodoActivo, this.nodoForm.value.bot, 0, tipo);
							}
						}
						this.dialogRef.close(true);
					});
				}
				else{
					this.snackBar.open("Nodo ya existente, ingrese otro nombre", '', { duration: 3000 });
				}
			}); 
		}
	}

	guardarNodo() {
		console.log(this.nodoForm.value.codigo_tipo_nodo);
		this.nodoForm.value.tipo_nodo = this.tiposNodo.find(x => x.codigo_tipo_nodo == this.nodoForm.value.codigo_tipo_nodo).nombre;
		console.log(this.nodoForm.value.tipo_nodo);
		if(this.nodoForm.value.tipo_nodo != 'menu' && this.nodoForm.value.tipo_nodo != 'quickresponse' && this.parametros.length > 1)
		{
			this.snackBar.open("El Tipo de Nodo seleccionado solo acepta 1 parametro!", '', { duration: 3000 });
		}else{
			console.log(this.nodoForm.value.tipo_nodo);
			//this.nodoForm.controls.seleccion.setValue(this.nodoForm.value.tipo_seleccion + "@" + this.nodoForm.value.variable);
			console.log(this.nodoForm.value.tipo_nodo);
			this.services.insertNode(this.nodoForm.value).subscribe(data => {
				console.log(this.nodoForm.value.tipo_nodo);
				var resultado = data[0].resultado;
				if (resultado != -1) {
					console.log(this.nodoForm.value.tipo_nodo);
					this.nodoActivo = resultado;
					this.nombreActivo = this.nodoForm.value.nombre;
					var params = {frases: this.parametros, nodo: this.nodoActivo, tipo: this.nodoForm.value.tipo_nodo, codigo_nodo: this.nodoForm.value.codigo_nodo, parametro: this.nodoForm.value.parametro, codigo_parametro: this.nodoForm.value.codigo_parametro, direccion_parametro: this.nodoForm.value.direccion_parametro};
					this.services.insertNodeOutput(params).subscribe(data => {
						if(typeof(this.board) != 'undefined'){
							console.log(this.nodoForm.value.tipo_nodo);
							let tipo = this.nodoForm.value.tipo_nodo;
							console.log(this.nodoForm.value);
							if(tipo === 'menu'){
								this.services.getOutputsByNode({codigo:this.nodoActivo}).subscribe(outputs => {
									this.board.addMenu(this.nodoForm.value.nombre, this.nodoActivo, this.nodoForm.value.bot, 0, tipo, outputs);
								});
							}else if(tipo === 'seleecion'){
								this.services.getOutputsByNode({codigo:this.nodoActivo}).subscribe(outputs => {
									this.board.addSeleccion(this.nodoForm.value.nombre, this.nodoActivo, this.nodoForm.value.bot, 0, tipo, outputs);
								});
							}
							else if(tipo === 'quickresponse')
							{
								this.services.getOutputsByNode({codigo:this.nodoActivo}).subscribe(outputs => {
									this.board.addQuick(this.nodoForm.value.nombre, this.nodoActivo, this.nodoForm.value.bot, tipo, outputs);
								});
							}
							else if(tipo === 'texto' || tipo === 'multimedia' || tipo === 'pedir_numero'){
								console.log("agrega nodo al board");
								this.board.addTexto(this.nodoForm.value.nombre, this.nodoActivo, this.nodoForm.value.bot, 0, tipo);
							}
						}
						this.dialogRef.close(true);
					});
					
				}
				else{
					this.snackBar.open("Nodo ya existente, ingrese otro nombre", '', { duration: 3000 });
				}
			});
		}
	}

	onNoClick(event): void {
		this.dialogRef.close(event);
	}

	getErrorMessage(element){
		if(element.hasError('required')){
			return "Campo requerido";
		}else if(element.hasError('maxlength')){
			return "Longitud maxima de " + element.errors.maxlength.requiredLength + " caracteres";
		}
	}

	openDialog(): void {
		const dialogRef = this.dialog.open(EmojisComponent, {
			data: {}
		});

		dialogRef.afterClosed().subscribe(result => {
			console.log(this.nodoForm.controls.testchip.value);
			console.log(result);
			let auxvalue = this.nodoForm.controls.testchip.value;
			this.nodoForm.controls.testchip.setValue(auxvalue + result.colons);
		});
	}
}